@font-face {
  font-family: 'miller';
  font-style: italic;
  src: url('./assets/fonts/MillerDisplayItalic.otf');
}
@font-face {
  font-family: 'miller';
  src: url('./assets/fonts/MillerTextRegular.otf');
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: rgb(246, 246, 246);
  width: 8px;
  height: 8px;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  border: 0px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
  background-clip: padding-box;
  /* -webkit-border-radius: 1em; */
  background-color: #f08219;
  box-shadow: inset 0 0 0 1px #f08219;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

@media print {
  #configuration-resume {
    margin: 30px;
    width: calc(100vw - 60px);
  }
}
