.loading-animation .circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #bab3ad;
  left: 0%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
  top: 30px;
}

@keyframes circle {
  0% {
    top: 30px;
  }

  100% {
    top: 0%;
  }
}
.loading-animation .circle:nth-child(2) {
  left: 28%;
  animation-delay: 0.2s;
}
.loading-animation .circle:nth-child(3) {
  left: auto;
  right: 28%;
  animation-delay: 0.3s;
}
.loading-animation .circle:nth-child(4) {
  left: auto;
  right: 0%;
  animation-delay: 0.4s;
}
